export const filterDataByRange = (dates: string[], prices: number[]) => {

  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1); // Jan 1st of current year

  // Function to filter both arrays while keeping alignment
  const filterByDate = (cutoffDate: Date) => {
    return dates
      .map((date, index) => ({ date: new Date(date), price: prices[index], orgDate: date }))
      .filter(entry => entry.date >= cutoffDate)
      .map(entry => ({ date: entry.orgDate, prices: entry.price }));
  };

  return {
    fiveYears: filterByDate(new Date(today.getFullYear() - 5, today.getMonth(), today.getDate())),
    threeYears: filterByDate(new Date(today.getFullYear() - 3, today.getMonth(), today.getDate())),
    oneYear: filterByDate(new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())),
    sixMonths: filterByDate(new Date(today.getFullYear(), today.getMonth() - 6, today.getDate())),
    oneMonth: filterByDate(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())),
    ytd: filterByDate(startOfYear)
  };
};

export const calculatePeRatiosByRange = (
  filteredData: Record<string, { date: string; prices: number }[]>,
  epsTtmPerQuarter: number[],
  xaxisLabels: string[],
  reportedAt: string[] // New input: corresponding reportedAt dates for `epsTtmPerQuarter`
) => {
  // Create a lookup for EPS data with reportedAt dates
  const epsData = xaxisLabels.map((label, index) => ({
    quarter: label,
    eps: epsTtmPerQuarter[index],
    reportedAt: new Date(reportedAt[index]) // Convert `reportedAt` string to Date
  }));

  // Find the EPS that applies to a given date
  const getApplicableEPS = (currentDate: Date): number | null => {
    // Loop through `epsData` to find the most recent EPS valid for the given date
    for (let i = epsData.length - 1; i >= 0; i--) {
      const { reportedAt, eps } = epsData[i];
      if (currentDate >= new Date(reportedAt.getTime())) {
        // If `currentDate` is on or after the day EPS becomes valid
        return eps;
      }
    }

    // If no EPS is valid yet, return null
    return null;
  };

  const calculatePeRatio = (priceData: { date: string; prices: number }[]) => {
    return priceData.map(({ date, prices }) => {
      const currentDate = new Date(date); // Current date to calculate PE ratio
      const applicableEPS = getApplicableEPS(currentDate); // Get the applicable EPS for this date
      return {
        date,
        peRatio: applicableEPS && applicableEPS !== 0 ? Math.round((prices / applicableEPS) * 100) / 100 : null // Avoid division by zero
      };
    });
  };

  return Object.keys(filteredData).reduce((acc, range) => {
    acc[range] = calculatePeRatio(filteredData[range]);
    return acc;
  }, {} as Record<string, { date: string; peRatio: number | null }[]>);
};