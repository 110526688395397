export const myColorPalette = [
  '#E15759', // Red
  '#F28E2C', // Orange
  '#4E79A7', // Blue
  '#76B7B2', // Teal
  '#59A14F', // Green
  '#EDC948', // Yellow
  '#AF7AA1', // Purple
  '#FF9DA7', // Pink
  '#9C755F', // Brown
  '#BAB0AC'  // Gray
];