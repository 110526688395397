import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFetchWithAuth } from '../../../auth0/useFetchWithAuth';
import {
  BarPlot,
  LinePlot,
  ResponsiveChartContainer,
  ChartsLegend,
  ChartsTooltip,
  ChartsAxisHighlight,
  ChartsAxis,
  ChartsReferenceLine
} from '@mui/x-charts';
import {
  Container,
  Card,
  Typography,
  ToggleButton, ToggleButtonGroup,
  Link, Box, Tooltip
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Financials, Prices, Section, Series, XAxisLabelType } from './Financials';
import { sections } from './SectionConfiguration';
import { myColorPalette } from '../../MyColorPalette';
import { CompanyContext } from '../../../contexts/CompanyContext';
import CompanyLogoPageTitle from '../../../components/CompanyLogoPageTitle';
import SuspenseLoader from '../../../components/SuspenseLoader';
import { TotalChartsAxisTooltipContent } from './TotalChartsAxisTooltipContent';
import { filterDataByRange, calculatePeRatiosByRange } from './FinancialKernel';
import NestedMenu from 'src/components/NestedMenu';
import Footer from 'src/components/Footer';

function ChartsFinancials() {

  const defaultSection = sections.find(section => section.name === 'stockPrice');
  const { selectedCompany } = useContext(CompanyContext);
  const [financials, setFinancials] = useState<Financials | undefined>(undefined);
  const [prices, setPrices] = useState<Prices | undefined>(undefined);
  const [period, setPeriod] = useState('ttm');
  const [timeRange, setTimeRange] = useState('fiveYears');
  const [section, setSection] = useState(defaultSection);
  const fetchWithAuth = useFetchWithAuth();

  const handlePeriodChange = (event: React.MouseEvent<HTMLElement>, newPeriod: string) => {
    if (newPeriod != null) {
      setPeriod(newPeriod);
    }
    console.log(event, newPeriod);
  };

  const handleTimeRangeChange = (_event: React.MouseEvent<HTMLElement>, newTimeRange: string) => {
    if (newTimeRange != null) {
      setTimeRange(newTimeRange);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchWithAuth<Financials>('/api/financials?ticker=' + selectedCompany.ticker);
      setFinancials(response);
    };
    fetchData().then();
  }, [selectedCompany.ticker]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchWithAuth<Prices>('/api/prices?ticker=' + selectedCompany.ticker);
      setPrices(response);
    };
    fetchData().then();
  }, [selectedCompany.ticker]);

  function isTouchDevice() {
    return ('ontouchstart' in window || navigator.maxTouchPoints > 0
      || window.matchMedia('(pointer: coarse)').matches);
  }

  if (!financials) {
    return (<SuspenseLoader />);
  }

  section.xAxisData = financials.xaxisLabels;
  if (section.xAxisLabelsType == XAxisLabelType.COMPANY_SPECIFIC) {
    section.xAxisData = financials.xaxisLabelsCompanySpecific;
  }

  section.showKpis = financials.deliveriesModel3y.length > 0 && financials.energyStorageDeployed.length > 0;
  section.showDividends = financials.dividends.length > 0;

  const filteredPriceData = filterDataByRange(prices.dates, prices.prices);
  const priceDifference = prices.prices.at(-1) - filteredPriceData[timeRange][0].prices;

  const calculatedPeRatiosByRange = calculatePeRatiosByRange(filteredPriceData,
    financials.epsTtmPerQuarter, financials.xaxisLabels, financials.reportedAt);

  const peRatioData = calculatedPeRatiosByRange[timeRange].map((entry: { peRatio: any; }) => entry.peRatio);
  const currentPeRatio = peRatioData.at(-1);
  const lowestPeRatio = Math.min(...peRatioData.filter(value => value > 0));
  const highestPeRatio = Math.max(...peRatioData);
  const averagePeRatio = peRatioData.filter(value => value > 0).reduce((sum, value) => sum + value, 0) / peRatioData.length;

  function switchResult(section: Section): Series[] {
    switch (section.name) {
      default:
        return [];
      case 'stockPrice':
        section.xAxisData = filteredPriceData[timeRange].map((entry: { date: any; }) => entry.date);
        return [{
          type: 'line',
          label: 'Stock Price',
          data: filteredPriceData[timeRange].map((entry: { prices: any; }) => entry.prices)
        }];
      case 'peRatio':
        section.xAxisData = calculatedPeRatiosByRange[timeRange].map((entry: { date: any; }) => entry.date);
        return [{
          label: 'PE Ratio',
          data: peRatioData
        }];
      case 'topline':
        section.convertToHigherUnit = Math.max(...financials.revenueData) > 1000;
        return [{
          label: 'Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.grossProfitTtmData : financials.grossProfitData.slice(3)
        }, {
          label: 'Cost of Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.costOfRevenueTtmData : financials.costOfRevenueData.slice(3)
        }, {
          label: 'Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.revenueTtmData : financials.revenueData.slice(3)
        }];
      case 'profit':
        section.convertToHigherUnit = Math.max(...financials.ebitdaData) > 1000;
        return [{
          label: 'Net Income' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.netIncomeTtmData : financials.netIncomeData.slice(3)
        }, {
          label: 'EBIT' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.ebitTtmData : financials.ebitData.slice(3)
        }, {
          label: 'EBITDA' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.ebitdaTtmData : financials.ebitdaData.slice(3)
        }];
      case 'margins':
        return [{
          type: 'line',
          label: 'Gross Profit Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.grossProfitMarginTtm : financials.grossProfitMargin.slice(3)
        }, {
          type: 'line',
          label: 'EBITDA Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.ebitdaMarginTtm : financials.ebitdaMargin.slice(3)
        }, {
          type: 'line',
          label: 'Operating Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.operatingMarginTtm : financials.operatingMargin.slice(3)
        }, {
          type: 'line',
          label: 'Net Income Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.netIncomeMarginTtm : financials.netIncomeMargin.slice(3)
        }, {
          type: 'line',
          label: 'FCF Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.fcfMarginTtm : financials.fcfMargin.slice(3)
        }];
      case 'cash':
        section.convertToHigherUnit = Math.max(...financials.ocfData) > 1000;
        return [{
          label: 'Operating Cashflow' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.ocfTtmData : financials.ocfData.slice(3)
        }, {
          label: 'Capital Expenditures' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.capexTtmData : financials.capexData.slice(3)
        }, {
          label: 'Free Cashflow' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.fcfTtmData : financials.fcfData.slice(3)
        }];
      case 'balanceSheet':
        section.convertToHigherUnit = Math.max(...financials.assets) > 1000;
        return [{
          label: 'Total Debt',
          data: financials.totalDebt.slice(3)
        }, {
          label: 'Cash And Cash Equivalents',
          data: financials.cashAndCashEquivalents.slice(3)
        }, {
          label: 'Assets',
          data: financials.assets.slice(3)
        }];
      case 'performance':
        return [{
          type: 'line',
          label: 'ROE',
          data: financials.roe.slice(3)
        }, {
          type: 'line',
          label: 'ROIC',
          data: financials.roic.slice(3)
        }, {
          type: 'line',
          label: 'CFROIC',
          data: financials.cfroic
        }];
      case 'eps':
        return [{
          label: 'EPS' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.epsTtmPerQuarter : financials.epsPerQuarter.slice(3)
        }];
      case 'shares':
        section.convertToHigherUnit = Math.max(...financials.numberOfShares) > 1000;
        return [{
          label: 'Shares Outstanding',
          data: financials.numberOfShares.slice(3)
        }];
      case 'equity':
        section.convertToHigherUnit = Math.max(...financials.equity) > 1000;
        return [{
          label: 'Equity' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.equityTtm.slice(3) : financials.equity.slice(3)
        }];
      case 'investedCapital':
        section.convertToHigherUnit = Math.max(...financials.investedCapital) > 1000;
        return [{
          label: 'Invested Capital',
          data: financials.investedCapital.slice(3)
        }];
      case 'operationalLeverage':
        section.convertToHigherUnit = Math.max(...financials.revenueData) > 1000;
        return [{
          type: 'line',
          label: 'Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.revenueTtmData : financials.revenueData.slice(3)
        }, {
          type: 'line',
          label: 'Capital Expenditures' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.capexTtmData : financials.capexData.slice(3)
        }, {
          type: 'line',
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.opExDataTtm : financials.opExData.slice(3)
        }];
      case 'opExVsGrossProfit':
        section.convertToHigherUnit = Math.max(...financials.grossProfitData) > 1000;
        return [{
          label: 'Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.grossProfitTtmData : financials.grossProfitData.slice(3)
        }, {
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.opExDataTtm : financials.opExData.slice(3)
        }, {
          label: 'Selling General and Administrative' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.sgaTtm : financials.sga.slice(3)
        }, {
          label: 'Research & Development' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.rndTtm : financials.rnd.slice(3)
        }];
      case 'opExDistribution':
        section.convertToHigherUnit = Math.max(...financials.opExData) > 1000;
        return [{
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.opExDataTtm : financials.opExData.slice(3)
        }, {
          label: 'Selling General and Administrative' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.sgaTtm : financials.sga.slice(3)
        }, {
          label: 'Research & Development' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.rndTtm : financials.rnd.slice(3)
        }, {
          label: 'Selling & Marketing Expense TTM' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.sellingMarketingTtm : financials.sellingMarketing.slice(3)
        }, {
          label: 'General & Administrative Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.gnaTtm : financials.gna.slice(3)
        }];
      case 'opExVsCapEx':
        section.convertToHigherUnit = Math.max(...financials.opExData) > 1000;
        return [{
          type: 'line',
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.opExDataTtm : financials.opExData.slice(3)
        }, {
          type: 'line',
          label: 'Capital Expenditures' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.capexTtmData : financials.capexData.slice(3)
        }];
      case 'opExVsCapExMargin':
        return [{
          type: 'line',
          label: 'Operating Expense Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.opExMarginTtm : financials.opExMargin.slice(3)
        }, {
          type: 'line',
          label: 'Capital Expenditures Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.capExMarginTtm : financials.capExMargin.slice(3)
        }];
      case 'dividends':
        return [{
          label: 'Dividends' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.dividendsTtm : financials.dividends.slice(3)
        }];
      case 'payoutRatio':
        return [{
          label: 'Payout Ratio' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.payoutRatioTtm : financials.payoutRatio.slice(3)
        }];
      case 'epsGrowth':
        return [{
          type: 'line',
          label: 'EPS Growth' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.epsGrowthTtm : financials.epsGrowth.slice(3)
        }];
      case 'netCashOpExCapEx':
        return [{
          type: 'line',
          label: 'Net Cash / OpEx + CapEx TTM',
          data: financials.netCashOpExCapExTtm
        }];
      case 'dividendYield':
        return [{
          type: 'line',
          label: 'Dividend Yield TTM',
          data: financials.dividendYieldTtm
        }];
      case 'ruleOf40':
        section.xAxisData = financials.xaxisLabels.slice(financials.xaxisLabels.length - 16);
        return [{
          type: 'line',
          label: 'Revenue Growth Rate YoY',
          data: financials.revenueGrowthRateYoY.slice(financials.revenueGrowthRateYoY.length - 16)
        }, {
          type: 'line',
          label: 'FCF Margin',
          data: financials.fcfMargin.slice(financials.fcfMargin.length - 16)
        }, {
          type: 'line',
          label: 'Rule of 40',
          data: financials.ruleOf40.slice(financials.ruleOf40.length - 16)
        }];
      case 'vehicleDeliveries':
        section.convertToHigherUnit = Math.max(...financials.deliveriesModel3y) > 1000;
        return [{
          label: 'Model 3 and Y' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.deliveriesModel3yTtm : financials.deliveriesModel3y.slice(3),
          stack: 'vehicles'
        }, {
          label: 'Model S, X and Cybertruck' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.deliveriesOtherModelsTtm : financials.deliveriesOtherModels.slice(3),
          stack: 'vehicles'
        }];
      case 'energyStorageDeployed':
        section.convertToHigherUnit = Math.max(...financials.energyStorageDeployed) > 1000;
        section.yAxisLabel = section.convertToHigherUnit ? 'in GWhs' : 'in MWhs';
        return [{
          label: 'Energy Storage Deployed' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.energyStorageDeployedTtm : financials.energyStorageDeployed.slice(3)
        }];
      case 'revenueBySegment':
        section.convertToHigherUnit = Math.max(...financials.tslaTotalAutomotiveRevenue) > 1000;
        return [{
          label: 'Total Automotive Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaTotalAutomotiveRevenueTtm : financials.tslaTotalAutomotiveRevenue.slice(3),
          stack: 'revenue'
        }, {
          label: 'Energy Generation and Storage Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaEnergyGenerationAndStorageRevenueTtm : financials.tslaEnergyGenerationAndStorageRevenue.slice(3),
          stack: 'revenue'
        }, {
          label: 'Services and Other Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaServicesAndOtherRevenueTtm : financials.tslaServicesAndOtherRevenue.slice(3),
          stack: 'revenue'
        }];
      case 'costOfRevenueBySegment':
        section.convertToHigherUnit = Math.max(...financials.tslaTotalAutomotiveCostOfRevenue) > 1000;
        return [{
          label: 'Total Automotive Cost of Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaTotalAutomotiveCostOfRevenueTtm : financials.tslaTotalAutomotiveCostOfRevenue.slice(3),
          stack: 'costOfRevenue'
        }, {
          label: 'Energy Generation and Storage Cost of Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaEnergyGenerationAndStorageCostOfRevenueTtm : financials.tslaEnergyGenerationAndStorageCostOfRevenue.slice(3),
          stack: 'costOfRevenue'
        }, {
          label: 'Services and Other Cost of Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaServicesAndOtherCostOfRevenueTtm : financials.tslaServicesAndOtherCostOfRevenue.slice(3),
          stack: 'costOfRevenue'
        }];
      case 'grossProfitBySegment':
        section.convertToHigherUnit = Math.max(...financials.tslaTotalAutomotiveGrossProfit) > 1000;
        return [{
          label: 'Total Automotive Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaTotalAutomotiveGrossProfitTtm : financials.tslaTotalAutomotiveGrossProfit.slice(3),
          stack: 'grossProfit'
        }, {
          label: 'Energy Generation and Storage Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaEnergyGenerationAndStorageGrossProfitTtm : financials.tslaEnergyGenerationAndStorageGrossProfit.slice(3),
          stack: 'grossProfit'
        }, {
          label: 'Services and Other Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaServicesAndOtherGrossProfitTtm : financials.tslaServicesAndOtherGrossProfit.slice(3),
          stack: 'grossProfit'
        }];
      case 'grossMarginsBySegment':
        return [{
          type: 'line',
          label: 'Total Automotive Gross Profit Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaTotalAutomotiveGrossProfitMarginTtm : financials.tslaTotalAutomotiveGrossProfitMargin.slice(3)
        }, {
          type: 'line',
          label: 'Energy Generation and Storage Gross Profit Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaEnergyGenerationAndStorageGrossProfitMarginTtm : financials.tslaEnergyGenerationAndStorageGrossProfitMargin.slice(3)
        }, {
          type: 'line',
          label: 'Services and Other Gross Profit Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaServicesAndOtherGrossProfitMarginTtm : financials.tslaServicesAndOtherGrossProfitMargin.slice(3)
        }];
      case 'averageVehicleSellingPrice':
        return [{
          label: 'Average Vehicle Selling Price',
          data: financials.tslaAverageVehicleSellingPrice.slice(financials.tslaAverageVehicleSellingPrice.length - financials.xaxisLabels.length)
        }];
      case 'automotiveRegulatoryCredits':
        return [{
          label: 'Automotive Regulatory Credit Sales' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? financials.tslaAutomotiveRegulatoryCreditsTtm : financials.tslaAutomotiveRegulatoryCredits.slice(3)
        }];
    }
  }

  const series = switchResult(section);

  if (series[0].data.length <= 0) { // if section has no data (e.g. if company has no dividends or KPIs, jump to default section
    setSection(defaultSection);
  }

  // @ts-ignore
  return (
    <>
      <Helmet>
        <title>Charts</title>
      </Helmet>

      <CompanyLogoPageTitle />

      <Container maxWidth="xl">
        <Grid container>
          <Grid size={{ xs: 12 }}>
            <Card>

              <Grid justifyContent="space-between" display={'flex'}>

                <Box alignItems="left" sx={{ marginTop: 1, marginLeft: 1 }}>
                  <NestedMenu
                    onClick={(newSection: string) => setSection(sections.find(section => section.name === newSection))}
                    showKpis={section.showKpis}
                    showDividends={section.showDividends}
                  />
                </Box>

                <Box alignItems="center" sx={{
                  marginTop: 2, display: {
                    xs: 'none',
                    sm: 'block'
                  }
                }}>
                  <Box display={'flex'}>
                    {section.icon}&nbsp;<Typography variant={'h4'}
                                                    sx={{ mt: 0.3 }}>{section.label ? section.label : section.name}</Typography>
                  </Box>
                </Box>

                <Box alignItems="right" sx={{ marginTop: 1, marginRight: 1 }}>
                  {section.name != 'stockPrice' && section.name != 'peRatio' &&
                    <ToggleButtonGroup
                      value={section.periodIndependent ? null : period}
                      exclusive
                      size={'small'}
                      fullWidth={false}
                      onChange={handlePeriodChange}
                      disabled={section.periodIndependent}>
                      <ToggleButton value="quarterly">Quarterly</ToggleButton>
                      <ToggleButton value="ttm">TTM</ToggleButton>
                    </ToggleButtonGroup>}
                  {(section.name == 'stockPrice' || section.name == 'peRatio') &&
                    <ToggleButtonGroup
                      value={timeRange}
                      exclusive
                      size={'small'}
                      fullWidth={false}
                      onChange={handleTimeRangeChange}>
                      <ToggleButton value="fiveYears">5y</ToggleButton>
                      <ToggleButton value="threeYears">3y</ToggleButton>
                      <ToggleButton value="oneYear">1y</ToggleButton>
                      <ToggleButton value="sixMonths">6m</ToggleButton>
                      <ToggleButton value="oneMonth">1m</ToggleButton>
                      <ToggleButton value="ytd">ytd</ToggleButton>
                    </ToggleButtonGroup>}
                </Box>

              </Grid>

              <Grid container height={'68vh'}>

                {section.name == 'shares' &&
                  <Typography variant="h4" sx={{ ml: 'auto', mr: 'auto', pt: 2, pb: 1 }}>
                    Ideally no increase in past 10 years, stays the same or deceases.
                  </Typography>
                }
                {section.name == 'netCashOpExCapEx' &&
                  <Typography variant="h4" sx={{ ml: 8, mr: 8, pt: 1, pb: 0 }}>
                    Net Cash / (OpEx TTM + CapEx TTM) represents a financial ratio that provides insight into a
                    company's financial health and operational efficiency. A higher ratio suggests the company is in a
                    stronger balance sheet and has better operational efficiency. It's best to compare this ratio
                    against other companies (<Link href="https://x.com/freshjiva/status/1583093721721368576"
                                                   target="_blank" rel="noopener noreferrer"
                                                   color="primary">see Mayur Thaker's post</Link>).
                  </Typography>
                }
                {section.name == 'ruleOf40' &&
                  <Typography variant="h4" sx={{ ml: 8, mr: 8, pt: 1, pb: 0 }}>
                    The Rule of 40 is a guideline often used in the technology and software-as-a-service (SaaS) sectors
                    to evaluate the performance and growth potential of a company. It balances revenue growth and
                    profitability to provide a simple metric for investors and management to assess whether the company
                    is on a healthy growth trajectory (<Link
                    href="https://chatgpt.com/share/ae4e4d14-59d0-4162-9a35-2a5dc8853298"
                    target="_blank" rel="noopener noreferrer"
                    color="primary">see Rule of 40 Overview</Link>).
                  </Typography>
                }
                {section.name != 'ruleOf40' && section.name != 'netCashOpExCapEx' && section.name != 'shares' &&
                  <Typography variant="h4" sx={{ ml: 8, mr: 8, pt: 1, pb: 0 }} height={30}>&nbsp;</Typography>
                }

                {section.name != 'stockPrice' && section.name != 'peRatio' &&
                  <ResponsiveChartContainer
                    xAxis={[{
                      scaleType: 'band',
                      data: section.xAxisData,
                      valueFormatter: (value) => value.slice(0, 2) + '\n' + value.slice(2)
                    }]}
                    yAxis={[{
                      label: section.yAxisLabel,
                      valueFormatter: (value: number) => {
                        return section.unit.prefix + (section.convertToHigherUnit ? value / 1000 : value) +
                          (section.yAxisLabel ? '' : section.convertToHigherUnit ? section.unit.higherSuffix : section.unit.suffix);
                      }
                    }]}
                    colors={myColorPalette}
                    // @ts-ignore
                    series={series
                      .map(s => {
                        return ({
                          ...s,
                          type: s.type ? s.type : 'bar',
                          data: s.data,
                          highlightScope: { highlight: 'series', fade: 'global' },
                          valueFormatter: (value: number) => {
                            return section.unit.prefix + value + section.unit.suffix;
                          }
                        });
                      })}
                    sx={{ ml: 0, pl: 1, mr: -3, pr: 0, pb: 0, mb: 0 }}
                  >
                    <BarPlot />
                    <LinePlot />
                    <ChartsAxis />
                    <ChartsLegend />
                    <ChartsTooltip
                      slots={{
                        axisContent: TotalChartsAxisTooltipContent
                      }}
                      slotProps={{
                        axisContent: {
                          sx: { border: 'solid', borderWidth: 2, borderColor: 'divider' }
                        },
                        popper: {
                          placement: isTouchDevice() ? 'top' : 'auto-start',
                          modifiers: isTouchDevice() ? [{
                            name: 'offset',
                            options: {
                              offset: [0, 40] // [horizontal offset, vertical offset]
                            }
                          }] : []
                        }
                      }}
                    />
                    <ChartsAxisHighlight x={'band'} />
                    {section.name == 'ruleOf40' &&
                      <ChartsReferenceLine y={40}
                                           label="Rule of 40 Minimum"
                                           labelAlign={'start'}
                                           lineStyle={{
                                             strokeDasharray: '5 5'
                                           }} />
                    }
                  </ResponsiveChartContainer>}

                {section.name == 'stockPrice' &&
                  <Grid display="flex" width="100%" justifyContent="center" alignItems="center" sx={{ mb: 1 }}>
                    <Typography variant="h4">
                      ${prices.prices.at(-1).toFixed(2)}&nbsp;
                      <Typography component="span" variant="h5"
                                  sx={{ color: priceDifference > 0 ? '#59A14F' : '#E15759' }}>
                        {(priceDifference > 0 ? '+' : '') + priceDifference.toFixed(2)}
                        &nbsp;({(priceDifference > 0 ? '+' : '')
                        + (priceDifference / filteredPriceData[timeRange][0].prices * 100).toFixed(2)}%)
                      </Typography>
                    </Typography>
                  </Grid>
                }
                {section.name == 'peRatio' &&
                  <Grid display="flex" width="100%" justifyContent="center" alignItems="center" sx={{ mt: -2, mb: 3 }}>
                    <Typography variant="h5">
                      Lowest: <Typography component="span" variant="h5" sx={{ color: '#F28E2C' }}>{lowestPeRatio.toFixed(2)}</Typography> |
                      Highest: <Typography component="span" variant="h5" sx={{ color: '#4E79A7' }}>{highestPeRatio.toFixed(2)}</Typography> |
                      Average: <Typography component="span" variant="h5" sx={{ color: '#76B7B2' }}>{averagePeRatio.toFixed(2)}</Typography> |
                      Current: <Typography component="span" variant="h5" sx={{ color: '#AF7AA1' }}>{currentPeRatio.toFixed(2)}</Typography> |
                      Forward: <Tooltip
                      arrow
                      title={
                        currentPeRatio > financials.forwardPe ?
                          `The 'Forward' PE Ratio is lower than the 'Current' value indicating analysts assume earnings are improving in the next twelve month which is a good sign.` :
                          `The 'Forward' PE Ratio is higher than the 'Current' value indicating analysts assume earnings are worsening in the next twelve month which is a bad sign.`
                      }
                      enterTouchDelay={0}
                      leaveTouchDelay={10000}>
                      <Typography component="span" variant="h5"
                                  sx={{ color: currentPeRatio > financials.forwardPe ? '#59A14F' : '#E15759' }}>{financials.forwardPe}</Typography>
                    </Tooltip>
                    </Typography>
                  </Grid>
                }
                {(section.name == 'stockPrice' || section.name == 'peRatio') &&
                  <ResponsiveChartContainer
                    xAxis={[{
                      scaleType: 'band',
                      data: section.xAxisData,

                      valueFormatter: (value, context) => {
                        const date = new Date(value);
                        const isLastYearOrLess = timeRange !== 'fiveYears' && timeRange !== 'threeYears';

                        switch (context.location) {
                          case 'legend':
                          case 'tick':
                            if (timeRange == 'oneMonth' || (timeRange == 'ytd' && new Date(prices.dates[prices.dates.length - 1]).getMonth() < 2)) {
                              return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' }); // "Jan 18"
                            } else {
                              return isLastYearOrLess
                                ? date.toLocaleDateString('en-US', { month: 'short' }) // "Jan"
                                : date.getFullYear().toString(); // "2024"
                            }
                          case 'tooltip':
                            return value; // Keep full date format
                        }
                      },
                      tickInterval: (_v, index) => {

                        const currentDate = new Date(prices.dates[index]);
                        const previousDate = index > 0 ? new Date(prices.dates[index - 1]) : null;
                        const isLastYearOrLess = timeRange !== 'fiveYears' && timeRange !== 'threeYears';

                        if (isLastYearOrLess) {
                          if (timeRange == 'ytd' || (timeRange == 'ytd' && new Date(prices.dates[prices.dates.length - 1]).getMonth() < 2)) {
                            return currentDate.getDay() == 4;
                          } else if (timeRange == 'oneMonth') {
                            return currentDate.getDay() == 4;
                          } else {
                            // Show the month if it's different from the previous date
                            return previousDate != null && currentDate.getMonth() !== previousDate.getMonth();
                          }
                        } else {
                          // Show the year if it's different from the previous date
                          return previousDate != null && currentDate.getFullYear() !== previousDate.getFullYear();
                        }
                      },
                      tickPlacement: 'middle'
                    }]}
                    yAxis={[{
                      valueFormatter: (value: number) => {
                        return section.unit.prefix + value + section.unit.suffix;
                      }
                    }]}
                    colors={myColorPalette}
                    // @ts-ignore
                    series={series
                      .map(s => {
                        return ({
                          ...s,
                          type: s.type ? s.type : 'bar',
                          data: s.data,
                          curve: 'linear',
                          highlightScope: { highlight: 'series', fade: 'global' },
                          valueFormatter: (value: number) => {
                            return section.unit.prefix + value + section.unit.suffix;
                          }
                        });
                      })}
                    sx={{ ml: 0, pl: 1, mr: -3, pr: 0, pb: 0, mb: 0 }}
                  >
                    <BarPlot />
                    <LinePlot />
                    <ChartsAxis />
                    <ChartsTooltip
                      slotProps={{
                        axisContent: {
                          sx: { border: 'solid', borderWidth: 2, borderColor: 'divider' }
                        },
                        popper: {
                          placement: isTouchDevice() ? 'top' : 'auto-start',
                          modifiers: isTouchDevice() ? [{
                            name: 'offset',
                            options: {
                              offset: [0, 40] // [horizontal offset, vertical offset]
                            }
                          }] : []
                        }
                      }}
                    />
                    {section.name == 'peRatio' &&
                      financials.reportedAt.map((date, index) => (
                        <ChartsReferenceLine
                          key={index}
                          x={date}
                          label={`20${financials.xaxisLabels[index].replace(/(\d+)(Q\d+)/, '$1 $2')}\nEarnings\nreported`}
                          labelAlign="start"
                          labelStyle={{
                            fontSize: '10px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase'
                          }}
                          spacing={{ x: 0, y: -45 }}
                        />
                      ))
                    }
                  </ResponsiveChartContainer>}

              </Grid>
              <Grid container height={section.name == 'netCashOpExCapEx' || section.name == 'ruleOf40' ? 90 : 70} />
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
}

export default ChartsFinancials;
