import React from 'react';

export interface Financials {
  xaxisLabels: string[];
  xaxisLabelsCompanySpecific: string[];

  reportedAt: string[];

  forwardPe: number;

  revenueTtmData: number[];
  costOfRevenueTtmData: number[];
  grossProfitTtmData: number[];

  netIncomeTtmData: number[];
  ebitTtmData: number[];
  ebitdaTtmData: number[];

  ocfTtmData: number[];
  capexTtmData: number[];
  fcfTtmData: number[];

  revenueData: number[];
  costOfRevenueData: number[];
  grossProfitData: number[];

  netIncomeData: number[];
  ebitData: number[];
  ebitdaData: number[];

  ocfData: number[];
  capexData: number[];
  fcfData: number[];

  assets: number[];
  cashAndCashEquivalents: number[];
  totalDebt: number[];

  roe: number[];
  roic: number[];
  cfroic: number[];

  numberOfShares: number[];

  epsPerQuarter: number[];
  epsTtmPerQuarter: number[];

  grossProfitMargin: number[];
  grossProfitMarginTtm: number[];

  netIncomeMargin: number[];
  netIncomeMarginTtm: number[];

  ebitdaMargin: number[];
  ebitdaMarginTtm: number[];

  fcfMargin: number[];
  fcfMarginTtm: number[];

  operatingMargin: number[];
  operatingMarginTtm: number[];

  equity: number[];
  equityTtm: number[];

  investedCapital: number[];

  opExData: number[];
  opExDataTtm: number[];

  sga: number[];
  sgaTtm: number[];

  rnd: number[];
  rndTtm: number[];

  sellingMarketing: number[];
  sellingMarketingTtm: number[];

  gna: number[];
  gnaTtm: number[];

  opExMargin: number[];
  opExMarginTtm: number[];

  capExMargin: number[];
  capExMarginTtm: number[];

  dividends: number[];
  dividendsTtm: number[];

  payoutRatio: number[];
  payoutRatioTtm: number[];

  epsGrowth: number[];
  epsGrowthTtm: number[];

  netCashOpExCapExTtm: number[];

  dividendYieldTtm: number[];

  revenueGrowthRateYoY: number[];
  ruleOf40: number[];

  deliveriesModel3y: number[];
  deliveriesModel3yTtm: number[];

  deliveriesOtherModels: number[];
  deliveriesOtherModelsTtm: number[];

  tslaTotalAutomotiveRevenue: number[];
  tslaTotalAutomotiveRevenueTtm: number[];

  tslaTotalAutomotiveCostOfRevenue: number[];
  tslaTotalAutomotiveCostOfRevenueTtm: number[];

  tslaTotalAutomotiveGrossProfit: number[];
  tslaTotalAutomotiveGrossProfitTtm: number[];

  energyStorageDeployed: number[];
  energyStorageDeployedTtm: number[];

  tslaEnergyGenerationAndStorageRevenue: number[];
  tslaEnergyGenerationAndStorageRevenueTtm: number[];
  tslaEnergyGenerationAndStorageCostOfRevenue: number[];
  tslaEnergyGenerationAndStorageCostOfRevenueTtm: number[];
  tslaEnergyGenerationAndStorageGrossProfit: number[];
  tslaEnergyGenerationAndStorageGrossProfitTtm: number[];

  tslaServicesAndOtherRevenue: number[];
  tslaServicesAndOtherRevenueTtm: number[];
  tslaServicesAndOtherCostOfRevenue: number[];
  tslaServicesAndOtherCostOfRevenueTtm: number[];
  tslaServicesAndOtherGrossProfit: number[];
  tslaServicesAndOtherGrossProfitTtm: number[];

  tslaTotalAutomotiveGrossProfitMargin: number[];
  tslaTotalAutomotiveGrossProfitMarginTtm: number[];
  tslaEnergyGenerationAndStorageGrossProfitMargin: number[];
  tslaEnergyGenerationAndStorageGrossProfitMarginTtm: number[];
  tslaServicesAndOtherGrossProfitMargin: number[];
  tslaServicesAndOtherGrossProfitMarginTtm: number[];

  tslaAverageVehicleSellingPrice: number[];

  tslaAutomotiveRegulatoryCredits: number[];
  tslaAutomotiveRegulatoryCreditsTtm: number[];
}

export interface Prices {
  dates: string[];
  prices: number[];
}

export interface Series {
  label: string;
  data: number[];
  type?: string;
  stack?: string;
}

export interface Section {
  name: string;
  label?: string;
  icon?: React.ReactNode;
  unit: Unit;
  periodIndependent: boolean;
  convertToHigherUnit?: boolean;
  xAxisData?: string[];
  xAxisLabelsType?: XAxisLabelType;
  yAxisLabel?: string;
  showKpis?: boolean;
  showDividends?: boolean;
}

export interface Unit {
  prefix: string;
  suffix: string;
  higherSuffix?: string;
}

export enum XAxisLabelType {
  COMPANY_SPECIFIC
}